@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

* {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

html {
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    touch-action: pan-x pan-y;
}

body {
    background-color: #ffffff;
    color: #2d2d2d;
    font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
    height: 100%;
    margin: 0px 0px 0px 0px;
    overflow-y: scroll;
    padding: 0px 0px 0px 0px;
}

input,
button,
textarea {
    font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
}

#app {
    height: 100%;
}
