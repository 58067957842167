@import "https://fonts.googleapis.com/css2?family=VT323&display=swap";
* {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -khtml-box-sizing: border-box;
}

html {
  touch-action: pan-x pan-y;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #2d2d2d;
  background-color: #fff;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, Open Sans, Arial, Helvetica, sans-serif;
  overflow-y: scroll;
}

input, button, textarea {
  font-family: Roboto, Open Sans, Arial, Helvetica, sans-serif;
}

#app {
  height: 100%;
}
/*# sourceMappingURL=index.a2a0ae7a.css.map */
